const CHARGIFY_JS_VERSION = '2022-08-29';
const CHARGIFY_JS_SHA = '6eca24782c2fe81474bec8812a78a34de2e85403';

export default {
  // turn on or off the console logs from the app
  debug: false,
  version: CHARGIFY_JS_VERSION,

  server: {
    // path where static assets are located
    // jsHost: 'https://js.chargify.com/v/' + CHARGIFY_JS_VERSION,

    // path for the HTML of each iframe (i.e. '/path/to/html')
    iframePath: '/hosted-field.html',

    // path for the HTML to main iframe
    mainIframePath: '/main-iframe.html',

    // path for the API to the get token (i.e. '/path/to/token')
    tokenPath: '/js/tokens.json',
  },

  // these numbers will be valid for credit card number (string)
  creditCardTestNumbers: ['1', '2', '3'],

  Honeybadger: {
    apiKey: '267a513a',
    revision: CHARGIFY_JS_VERSION,
    enableUncaught: false,
    enableUnhandledRejection: false,
    projectRoot: 'webpack:///./src/',
  },
};
